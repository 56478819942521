import Vue from 'vue'
import on from 'dom-event'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { trapFocus, removeTrapFocus } from '@shopify/theme-a11y'
const SHOW_MODAL_CLASS = 'is-open-modal'

Vue.component('modal', {
  props: [
    'showModal',
    'content',
    'notFocusButton'
  ],
  methods: {
    close: function (e) {
      removeTrapFocus()
      this.$emit('close', e)
      document.body.classList.remove(SHOW_MODAL_CLASS)
    }
  },
  mounted () {
    // Escape key closes modal
    on(window, 'keyup', event => {
      if (event.keyCode === 27 && this.showModal) {
        this.close()
      }
    })
  },
  watch: {
    showModal (value) {
      if (!value) {
        clearAllBodyScrollLocks()
        clearTimeout(this.timeout)
        return
      }

      // Trap focus in the modal element
      this.$nextTick(() => {
        document.body.classList.add(SHOW_MODAL_CLASS)
        trapFocus(this.$el, {
          elementToFocus: this.$refs.modalContainer
        })
      })

      // Set focus on close button
      this.timeout = setTimeout(() => {
        if (!this.notFocusButton) {
          const closeButtonEl = this.$refs.closeButton.length ? this.$refs.closeButton[0] : this.$refs.closeButton
          closeButtonEl.focus()
        }
      }, 100)

      disableBodyScroll(this.$refs.modalContainer)
    }
  }
})
